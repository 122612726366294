import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/home',
    name: 'home',
    component: () => import('../views/Home/index.vue'),
    meta: { requiresAuth: true },
    children: [
      {
        path: 'networkdisk',
        name: '网盘',

        component: () => import('../views/Networkdisk/index.vue'),
        meta: { requiresAuth: true },
        children: [
          {
            path: 'file',
            name: '文件',
            component: () => import('../views/File/index.vue'),
            meta: { requiresAuth: true },

          }
        ]

      }, {
        path: 'storage',
        name: '存储',

        meta: { requiresAuth: true },

        component: () => import('../views/Storage/index.vue'),

      }, {
        path: 'transfer',
        meta: { requiresAuth: true },

        component: () => import('../views/Transfer/index.vue'),

      }, {
        path: 'userinfo',
        meta: { requiresAuth: true },

        component: () => import('../views/UserInfo/index.vue'),

      },
      {
        path: 'file',
        name: '文件',

        meta: { requiresAuth: true },

        component: () => import('../views/File/index.vue'),

      },
      {
        path: 'carousel',
        meta: { requiresAuth: true },
        component: () => import('../views/admin/carousel.vue'),

      }, {
        path: 'share',
        meta: { requiresAuth: true },
        component: () => import('../views/Share/share.vue'),

      },


    ]
  }, {
    path: "/",
    name: 'login',
    meta: { requiresAuth: false },

    component: () => import('@/views/Login/index.vue')
  }, {
    path: "/register",
    meta: { requiresAuth: false },
    component: () => import('@/views/Register/register.vue')

  }, {
    path: "/smscode",
    meta: { requiresAuth: false },
    component: () => import('@/views/SmsCodeLogin/index.vue')

  }

]

const router = new VueRouter({
  // mode: 'history',
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (localStorage.getItem('token')) {
    next()
  } else {
    if (to.meta.requiresAuth) {
      next('/')
    } else {
      next()
    }
  }
})
export default router

