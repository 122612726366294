import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // 登录后的用户数据
    userinfo: {
      expireAt: '',
      id: 0,
      refreshAfter: '',
      username: ''
    },
    // 用户基础信息接口请求数据
    userdata: {
      id: 0,
      username: '',
      mobile: '',
      create_at: '',
      update_at: ''
    },
    disk: [],
    storage: []
  },
  getters: {
  },
  mutations: {
    setUserinfo(state, info) {
      state.userinfo.expireAt = info.expireAt
      state.userinfo.id = info.id
      state.userinfo.refreshAfter = info.refreshAfter
      state.userinfo.username = info.username
    },
    setDisk(state, networkInfo) {
      state.disk = networkInfo
    },
    setSorage(state, storageInfo) {
      state.storage = storageInfo
    },
    setUserData(state, userinfo) {
      state.userdata = userinfo
    }
  },
  actions: {
  },
  modules: {
  },
  plugins: [createPersistedState()]

})
