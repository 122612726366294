
import CryptoJS from "crypto-js";
var AES_KEY="lgb24kcs.cn"
var IV = "5"

//  data：要加密的字符串
//  key：秘钥
//  iv：偏移量
export default {
  encrypt(data) {
    // 统一将传入的字符串转成UTF8编码，这里的key一般是后端生成
    const key = CryptoJS.enc.Utf8.parse(AES_KEY)
    const iv = CryptoJS.enc.Utf8.parse(IV)
    const encrypt = CryptoJS.AES.encrypt(data, key, {
      iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });
    return encrypt.toString();
  }
}